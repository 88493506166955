<template>
  <div class="container-fluid">
    <div class="card">
        <div id="report" class="card-body">
            <div class="container-fluid">
              <div style="width: 80px;">
                <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
              </div>
              <div class="headerreport">
                <div class="row">
                  {{ companydata.cmp_nmbre }}
                </div>
                <div class="row">
                  {{ fecha }}
                </div>
              </div>
            </div>
            <br>          
            <div class="card-title">
              <h1 class="titlereport">Historial de cambios</h1>
              <h2 class="subtitle"><b>Documento:</b> {{ $route.params.rad }}</h2>
            </div>
            <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                <table id="reporteTiemposRespuesta" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>Versión</th>
                            <th>Fecha</th>
                            <th>Evento</th>
                            <th>Usuario</th>
                            <th>Cambios</th>
                        </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, index) in items">
                        <tr class="rowstyle">
                          <td>{{ item.version }}</td>
                          <td>{{ item.timestamp | formatDate }}</td>
                          <td>{{ findEvent(item.event) }}</td>
                          <td>{{ findUser(item.user) }}</td>
                          <td>{{ findDiff(item.diff) }}</td>
                        </tr>
                      </template>
                    </tbody>
                </table>
            </div>
            <br>
            <div v-if="showProgress" style="text-align:center">
              <v-progress-circular
                :size="50"
                :width="5"
                color="blue"
                indeterminate
              ></v-progress-circular>
            </div>
        </div>
    </div>
    <br>
    <div class="row justify-content-around" align="center">
      <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
      <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
      <button type="button" class="btn btn-secondary" v-on:click.prevent="cancel()">Regresar</button>      
    </div>
  </div>
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate, formatBoolean } from '../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';
import { tiempoUtilizado } from '../../utils';

export default {
  components: { 
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      tpodocs: [],
      tpopqrs: [],
      dependencias: [],
      series: [],
      indices: [],
      debug: null,
      fecha: null,
      tcr_nmbre: '',
      showProgress: false,
      events: [
        { id: 'created', name: 'Creado' },
        { id: 'updated', name: 'Editado' },
        { id: 'removed', name: 'Eliminado' }
      ],
      usuarios: []
    }
  },
  filters: {
    formatDate, formatBoolean
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.fetchUsuario(err => {
      if (err){
        alert('Error al consultar los usuarios.');
      } else {
        this.fetchTpocor(err => {
          if (err){
            alert('Error al consultar las clases de documentos.');
          } else {
            this.fetchTpodoc(err => {
              if (err){
                alert('Error al consultar los tipos de documento.');
              } else {
                this.fetchTpopqrs(err => {
                  if (err){
                    alert('Error al consultar los tipos de pqrs.');
                  } else {
                    this.fetchDependencia(err => {
                      if (err){
                        alert('Error al consultar las dependencias.');
                      } else {
                        this.fetchSerie(err => {
                          if (err){
                            alert('Error al consultar las series.');
                          } else {
                            this.fetchIndice(err => {
                              if (err){
                                alert('Error al consultar los índices.');
                              } else {
                                this.debug = this.$route.params;
                                this.searchItems(this.$route.params);                              
                              }
                            });
                          }
                        });
                      }
                    });
                  }
                });
              }
            });
          }
        });
      }
    });
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchTpocor(callback){
      let uri = '/tpocors/correspondencia/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchTpodoc(callback){
      let uri = '/tpodocs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpodocs = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchTpopqrs(callback){
      let uri = '/tpopqrs/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpopqrs = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchDependencia(callback){
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchSerie(callback){
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchIndice(callback){
      let uri = '/indices/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.indices = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    fetchUsuario(callback){
      let uri = '/usuarios/' + String(this.$store.state.company);
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.get(uri).then((response) => {
        this.usuarios = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    findEvent(id){
      const event = this.events.find(i => i.id === id);
      if (event){
        return event.name;
      } else {
        return 'Editado';
      }
    },
    findUser(id){
      const user = this.usuarios.find(i => i._id === id);
      if (user){
        return user.usu_nmbre + ' ' + user.usu_aplldo;
      } else {
        return 'Sistema';
      }
    },
    findDiff(d){
      const c = {
        _id: { name: 'ID' },
        tcr_id: { name: 'Clase doc.', array: this.tpocors, field: 'tcr_nmbre' },
        dcm_ano: { name: 'Año' },
        dcm_cdgo: { name: 'Consecutivo' },
        dcm_fcharad: { name: 'Fecha', type: 'Date' },
        tdo_id: { name: 'Tipo doc.', array: this.tpodocs, field: 'tdo_nmbre' },
        tta_id: { name: 'Tipo tabla', array: this.tpotablas, field: 'tta_nmbre' },
        dep_id: { name: 'Dependencia', array: this.dependencias, field: 'dep_nmbre' },
        ser_id: { name: 'Serie', array: this.series, field: 'ser_nmbre' },
        dcm_fchaini: { name: 'Fecha inicio', type: 'Date' },
        dcm_fchafin: { name: 'Fecha fin', type: 'Date' },
        dcm_electronico: { name: 'Doc. electrónico', type: 'Boolean' },
        dcm_archivado: { name: 'Archivado', type: 'Boolean' },
        tpa_id: { name: 'Tipo archivo', array: this.tpoarchs, field: 'tpa_nmbre' },
        sal_id: { name: 'Sala', array: this.salas, field: 'sal_nmbre' },
        caj_cdgo: { name: 'Caja' },
        lgj_nmro: { name: 'Legajo' },
        dcm_nflios: { name: 'Folios' },
        usu_id: { name: 'Usuario', array: this.usuarios, field: 'usu_nmbre' },
        dcm_rspsta: { name: 'Necesita respuesta', type: 'Boolean' },
        dcm_id_rs: { name: 'Respuesta' },
        dcm_soportes: { name: 'Soportes' },
        dcm_expediente: { name: 'Expediente' },
        dcm_rlcion: { name: 'Relación' },
        tav_nmro: { name: 'Versión tabla' },
        dcm_entidad: { name: 'Entidad' },
        dcm_ciudad: { name: 'Ciudad' },
        dcm_asunto: { name: 'Asunto' },
        dcm_anexos: { name: 'Anexos' },
        dcm_tres: { name: 'Tiempo respuesta' },
        dep_tramite_id: { name: 'Dependencia', array: this.dependencias, field: 'dep_nmbre' },
        dcm_tomo: { name: 'Tomo' },
        tpq_id: { name: 'Tipo solicitud', array: this.tpopqrs, field: 'tpq_nmbre' },
        dcm_web: { name: 'Pqrs web', type: 'Boolean' },
        dcm_resp_email: { name: 'Respondido email', type: 'Boolean' },
        ent_id: { name: 'Entidad', array: this.entidades, field: 'ent_nmbre' },
        ciud_id: { name: 'Ciudad', array: this.ciudades, field: 'ciud_nmbre' },
        dcm_direccion: { name: 'Dirección' },
        dcm_email: { name: 'Correo electrónico' },
        dcm_mensaje: { name: 'Mensaje' },
        tso_id: { name: 'Tipo solicitante', array: this.tposolics, field: 'tso_nmbre' },
        mdr_id: { name: 'Medio respuesta', array: this.medioresps, field: 'mdr_nmbre' },
        inb_id: { name: 'Buzón', array: this.inboxes, field: 'inb_user' },
        dcm_inbox_email: { name: 'Es Email', type: 'Boolean' },
        dcm_inbox_date: { name: 'Fecha Email', type: 'Date' },
        dcm_inbox_uid: { name: 'Uid Email' },
        dcm_inbox_uidvalidity: { name: 'Uid validity' },   
        indices: { name: 'Índices' },
        adjuntos: { name: 'Archivos adjuntos' },
        seguimientos: { name: 'Seguimientos' },
        emails: { name: 'Emails' }
      };

      const formatValue = (value, f) => {
        var newValue = null;
        var array = f.array;
        console.log(JSON.stringify(array));
        if (array){
          const ref = array.find(i => i._id === value);
          console.log(JSON.stringify(ref));
          if (ref){
            newValue = ref[f.field];
          } else {
            newValue = value;  
          }
        } else {
          newValue = value;  
        }

        switch (f.type) {
          case 'Date':
            return moment(new Date(newValue)).format('DD/MMM/YYYY hh:mm A');
            break;
        
          case 'Boolean':
            if (newValue) return 'Si'
            else return 'No';
            break;

          default:
            return newValue;
            break;
        }
      }

      var newObject = {};

      const entries = Object.entries(d);

      entries.forEach(([key, value]) => {
        var f = c[key];
        if (f){
          if (Array.isArray(value)){
            var a = [];
            value.forEach((i) => {
              a.push(formatValue(i, f));
            })
            newObject[f.name] = a;
          } else {
            newObject[f.name] = formatValue(value, f);
          }
        } else {
          newObject[key] = value;
        }
      });

      return newObject;
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando los datos';
        let uri = '/docs/report/history';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;
          this.items = [];

          for (var i = 0; i < list.length; i++){
            this.procesarItem(list[i], false);            
          }
          this.showProgress = false;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los cambios' + err;
        });
      }
    },
    procesarItem(item){
      this.items.push(item);      
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteHistoriaDocumento';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        report.push(this.procesarItemExport(this.items[i], i));
      }

      let item = {};
      report.push(item);

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de Historia de documento"],
        ["Documento: " + this.$route.params.rad],
        [],
        ["Versión", "Fecha", "Evento", "Usuario", "Cambios"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A8" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    },
    procesarItemExport(obj, i){
      let item = {};
      item.version = obj.version;
      item.timestamp = moment(obj.timestamp).format('DD/MMM/YYYY hh:mm A');
      item.event = this.findEvent(obj.event);
      item.user = this.findUser(obj.user);
      item.diff = this.findDiff(obj.diff);

      return item;      
    },
    cancel(){
      this.$router.replace({ name: 'DisplayDoc', params: { search: this.$route.params.search } });    
    }
  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

</style>
